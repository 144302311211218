<template>
  <div>
    <ayl-berad-nav :nav="nav"/>
    <div class="content-box">
      <div class="content-main">
    <ayl-table :table = "table">
      <div slot="ctrl-button">
        <el-button type="success" icon="el-icon-plus" @click="add()">新增</el-button>
        <!-- <div class="import-btn ml15px">导入</div> -->
        <div class="export-btn ml15px" @click="dialogExport=true">导出</div>
      </div>
    </ayl-table>
    </div>
    </div>
    <!--导出-->
    <el-dialog title="选择导出的数据" :visible.sync="dialogExport" width="540px" center>
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
        <el-checkbox :label="item.index" v-for="(item, idx) in checkList" :key="idx">{{item.value}}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exportExcel" :loading="btnLoading">确 定</el-button>
        <el-button @click="dialogExport=false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mixins from '../../../utils/mixins'
  export default {
    mixins: [mixins.$exportCheckAll],
    data () {
      const vm = this
      return {
        nav: [{name: "系统管理", path: ""}, {name: "人员管理", path: ""}],
        list: [],
        dialogExport: false,
        btnLoading: false,
        tableLoading: false,
        checkList: [],
        checkIndex: [],
        table: {
          api: vm.$api.getUserList,
          query: {
            gmtCreateStart: '',
            gmtCreateEnd: '',
            // gmtModifiedStart: '',
            // gmtModifiedEnd: '',
            status: null,
            queryContent: '',
          },
          searchData: [{
            type: 'select',
            title: '状态',
            model: 'status',
            placeholder: '请选择状态',
            option: this.$enums.userList.allList(),
          },
          {
            type: 'dateSection',
            title: '创建时间',
            startDate: ' ',
            endDate: ' ',
            modelName: ['gmtCreateStart', 'gmtCreateEnd']
          },
          // {
          //   type: 'dateSection',
          //   title: '更新时间',
          //   startDate: ' ',
          //   endDate: ' ',
          //   modelName: ['gmtModifiedStart', 'gmtModifiedEnd']
          // },
          {
            type: 'input',
            model: 'queryContent',
            placeholder: '用户账号、用户名称、创建人、所属公司、联系方式'
          } ],
          columns: [{
            title: '序号',
            width: '50px',
            $index: 'index',
          },{
            title: '用户账号',
            key: 'username',
            width: "120px"
          }, {
            title: '用户名称',
            key: 'nickName',
            width: "100px"
          }, {
            title: '联系方式',
            key: 'contact',
            width: '120px'
          }, {
            title: '创建时间',
            key: 'gmtCreate',
			      filter: 'str2ymd',
            width: '120px'
          },{
            title: '创建人',
            key: 'creatorName',
            width: '100px'
          },
          {
            title: '状态',
            key: 'status',
			      enumType: 'Status',
            width: '120px'
          },
           {
            title: '所属公司',
            key: 'company',
            showTooltip: true,
          },
          {
            title: '更新时间',
            key: 'gmtModified',
			      filter: 'str2ymd',
            width: '120px'
          },
           {
            title: '操作',
            width: '160px',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class:{
                    'table-view' : true,
                  },
                  on: {
                    click: vm.details.bind(this, ctx.row)
                  }
                }, '查看'),
                h('span', {
                  class:{
                    'table-view' : ctx.row.allowEdit,
                    'table-view-edit' : !ctx.row.allowEdit
                  },
                  on: {
                    click: ctx.row.allowEdit ? vm.update.bind(this, ctx.row) : vm.goNull.bind(this, ctx.row)
                  }
                }, '编辑'),
                h('span', {
                  class: {
                    'table-delete' : ctx.row.allowDelete,
                    'table-view-disable' : !ctx.row.allowDelete
                  },
                  on: {
                    click: ctx.row.allowDelete ? vm.delete.bind(this, ctx.row) : vm.goNull.bind(this, ctx.row)
                  }
                }, '删除')
              ])
            }
          }]
        }
      }
    },
    methods:{
	  //删除
      async delete(val){
        await this.$confirm(`您确认删除【${val.nickName}】吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        await this.$api.getUserDelete({
          userIdentity: val.userIdentity,
        })
        await this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        });
        await this.$search(this.table)
      },
	  goNull(){},
      add(){
        this.$router.push({path:'/system-manage/addUser'});
      },
	  //查看
    details(val){
      this.$router.push({
        path:'/system-manage/userDetails',
        query: {
          userIdentity: val.userIdentity
        }
      });
      },
      update(val){
        this.$api.getUserGet({
          userIdentity: val.userIdentity
        }).then((res) =>{
          this.$router.push({
            path: '/system-manage/updateUser',
            query: {
            userIdentity: val.userIdentity,
            roleNameList: res.roleNameList,
            roleList: res.roleList,
            userLevel: res.userLevel,
            phone: res.phone,
            nickName: res.nickName,
            companyIdList: res.companyIdList,
            allowChangePassword: res.allowChangePassword,
            userType: res.userType,
            email: res.email,
            username: res.username,
            status: res.status,
            remark: res.remark,
            }
          })
        })
      },
      //导出
      async exportExcel(){
        this.dialogExport = true
        try {
          const url = await this.$api.listUserInfoExport({
            //userType: this.table.query.userType,
            gmtCreateStart: this.table.query.gmtCreateStart,
            gmtCreateEnd: this.table.query.gmtCreateEnd,
            status: this.table.query.status,
            queryContent: this.table.query.queryContent,
            excelKey: 'listUserInfoExport',
            indexList: this.checkIndex,
          })
          window.open(url)
          this.dialogExport = false
        }catch (e) {}
        this.btnLoading = false
      },
    },
    async mounted () {
      await this.$search(this.table)
      // 导出
      this.$api.GetExcelExportKeyAndColumn({
        excelKey: "listUserInfoExport"
      }).then((res)=>{
        this.checkList = res.chsList
        this.checkIndex = this.checkList.map(e=>{
          return e.index
        })
      })
    }
  }
</script>
<style lang='sass' scoped>
/deep/ .table-view-disable
  padding: 0px 9px
  border-left: 1px solid $grayShallow3
  color: #ff999c
/deep/ .table-view-edit
  padding-right: 9px
  color: #99c0ff
</style>
